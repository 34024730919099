.admin-panel-page{
    margin-top: 10%;
    margin-bottom: 10%;
    min-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    /* max-width: 90vw; */
}
.admin-panel-page .posts-list-container{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}
.admin-panel-page .posts-list-container .post-card-admin-div{
    width: 20%;
    height: 550px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.admin-panel-page .posts-list-container .post-card-admin-div .post-card-admin-image-mask{
    width: 100%;
    height: 60%;
    overflow: hidden;
    border-radius: 15px;
}
.admin-panel-page .posts-list-container .post-card-admin-div .post-card-admin-image-mask img{
    height: 100%;
    width: 100%;
    object-fit: cover;

}
.admin-panel-page .posts-list-container .add-new-post-card{
    width: 20%;
    height: 550px;
    /* background-color: red; */
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}
.add-new-post-link{
    color: black;
    text-align: center;
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.admin-panel-page .posts-list-container .add-new-post-card .add-icon{
    font-size: 70px;
}
.admin-panel-page .posts-list-container .add-new-post-card .add-text{
    font-size: 30px;
}
.admin-panel-page .posts-list-container .post-card-admin-div .post-card-admin-btns-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.post-card-admin-div .post-card-admin-text{
    font-size: 15px;
}
.admin-panel-page .posts-list-container .post-card-admin-div .post-card-admin-btns-div .post-card-admin-edite-div , .admin-panel-page .posts-list-container .post-card-admin-div .post-card-admin-btns-div .post-card-admin-delete-div {
    width: 45%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-panel-page .posts-list-container .post-card-admin-div .post-card-admin-btns-div .post-card-admin-delete-div{
    background-color: rgba(255, 55, 55, 1);
}
.admin-panel-page .posts-list-container .post-card-admin-div .post-card-admin-btns-div .post-card-admin-delete-div .post-card-admin-delete{
    height: 20px;
}
.admin-panel-page .posts-list-container .post-card-admin-div  .post-card-admin-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    height: 24%;
}

@media screen and (max-width: 1500px) {
    .admin-panel-page .posts-list-container .post-card-admin-div , .admin-panel-page .posts-list-container .add-new-post-card{
        width: 28%;


    }


}

@media screen and (max-width: 1000px) {
    .admin-panel-page .posts-list-container .post-card-admin-div , .admin-panel-page .posts-list-container .add-new-post-card{
        width: 43%;


    }
    .post-card-admin-div .post-card-admin-title{
        font-size: 17px;
    }
    .post-card-admin-div .post-card-admin-text{
        font-size: 14px;
    }


}
@media screen and (max-width: 700px) {
    .admin-panel-page .posts-list-container .post-card-admin-div , .admin-panel-page .posts-list-container .add-new-post-card{
        width: 47%;


    }
    .post-card-admin-div .post-card-admin-title{
        font-size: 16px;
    }
    .post-card-admin-div .post-card-admin-text{
        font-size: 13px;
    }


}
@media screen and (max-width: 600px) {
    .admin-panel-page .posts-list-container .post-card-admin-div , .admin-panel-page .posts-list-container .add-new-post-card{
        width: 100%;


    }
    .admin-panel-page{
        margin-top: 20%;

    }
    .post-card-admin-div .post-card-admin-title{
        font-size: 16px;
    }
    .post-card-admin-div .post-card-admin-text{
        font-size: 13px;
    }

}
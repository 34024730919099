.footer{
    background-color: #1d70b8;
    
    height: 30vh;
    
}
.footer .footer-content-div{
    display: flex;
    justify-content: space-around;
    height: 100%;
}
.footer-line-div .footer-white-line{
    height: 100%;
    width: 50px;
    background-color: white;

}
.footer .footer-info-div{
    width: 60%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}
.footer .footer-info-div .footer-info-phone{
    display: flex;
    gap: 25px;
}
.footer .footer-logo{
    background-color: white;
    padding: 15px 15px 30px 15px;
    border-radius: 0 0 100px 100px;
    
}
.footer .footer-logo img{
    height: 35px;
}
.footer .footer-icon{
    height: 30px;
}
.footer .footer-content-div p{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px
}
.google-map-div{
    
    margin-top: 20px;
    /* margin-right: 50px; */
}
.google-map-div .google-map{
    height: 90%;
    width: 400px;
    border:none ;
    border-radius: 15px;
}
@media screen and (max-width: 640px) {
.footer-line-div{
    display: none;
}
.footer-logo-div{
    width: max-content;
}
.footer .footer-info-div{
    width: 100% !important;
padding: 5%;
}
.footer .footer-content-div{
    flex-direction: column;
    justify-content: space-between;
}
.footer{
    height: auto;
}
.google-map-div{
    text-align: center;
    margin-bottom: 10%;
}
.google-map-div .google-map{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}
.footer .footer-content-div p{
    font-size: 15px;
}
.footer .footer-info-div .footer-info-phone{
    flex-direction: column;
}
}

@media screen and (max-width: 1450px) {
    .footer-line-div{
        display: none;
    }
    
}
.home-product-mobile-div{
    margin-top: 15%;
}

.home-product-mobile-div .pentagon {
	position: relative;
	width: 126.6px;
	height: 121.5px;

	/* clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%); */
	clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
}
.home-product-mobile-div .product-mobile-image-mask img{
    height: 100%;
    width: 100%;
    object-fit: cover;
   
}

.home-product-mobile-div .product-mobile-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-product-mobile-div .product-mobile-flex.flex-reverse{
    flex-direction: row-reverse;
}
.flex-reverse .product-mobile-image-div{
    padding-left: 0;
}
.product-mobile-image-div{
    width: 40%;
   padding-left: 5%;
   
}
.home-product-mobile-div .product-mobile-content-div{
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.home-product-mobile-div .product-mobile-btn-div{
    margin-top: 5%;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}
.home-product-mobile-div .product-mobile-btn-div .btn{
    text-align: center;
    background-color: white;
    border: 2px solid #1d70b8;
}
.home-product-mobile-div .product-mobile-btn-div .btn a{
    color: #1d70b8;
    text-decoration: none;
   font-weight: bold;

}
.home-product-mobile-div .product-mobile-text-div{
    padding-left: 5%;
    padding-right: 5%;
    font-size: 14px;
}
.home-product-mobile-div .product-mobile-name-div{
    background-color: #1d70b8;
    
    padding: 5px 10px;

}
.home-product-mobile-div .product-mobile-name-div .product-mobile-name{
    text-transform: capitalize;
    color: white;
}
.product-mobile-name.product-mobile-name-farsi{
    text-align: right;
    padding-right: 5%;
}

@media screen and (max-width: 414px) {
    .home-product-mobile-div .product-mobile-name-div .product-mobile-name{
        font-size: 15px;
    }
    .home-product-mobile-div .product-mobile-btn-div .btn a{
        font-size: 15px;
    }
    .btn{
        font-size: 15px;
    }
  }
.blog-post-page{
    padding: 5%;

}
.blog-post-page .dir-rtl{
    direction: rtl;
}
.blog-post-page .blog-post-image-div {
    width: 100%;
    height: 500px;
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 3%;
}
.blog-post-page .blog-post-image-div .blog-post-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.blog-post-page .blog-post-title-div{
    margin-bottom: 2%;
}
.blog-post-page .blog-post-title{
    font-family: MonumentExtended , kalameh;
    text-transform: capitalize;
    font-size: 30px;
}

@media screen and (max-width: 640px) {
    .blog-post-page .blog-post-title{
        font-size: 15px;
    }
    .blog-post-page .blog-post-content{
        font-size: 16px;
    }
}
.navbar{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 30px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.navbar .contact-us-nav .btn p{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: max-content;
    gap: 7px;
    /* margin-top: 6%; */
}
.navbar .contact-us-nav.btn-reverse {
    flex-direction: row-reverse;
}

.navbar .contact-us-nav span{
    background-color: white;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    /* position: relative; */
    align-content: center;
    align-items: center;
    justify-content: center;

}
.navbar .contact-us-nav span img{
    /* position: absolute; */
   height: 15px;
 
}
.navbar .navbar-logo{
    height: 35px;
}
.navbar .navbar-links-div{
    display: flex;
    justify-content: space-between;
    width: 23%;
    align-items: center;
}
.navbar .navbar-links-div .link{
    padding: 5px;
    transition-duration: 1s;
    position: relative;
    font-size: 21px;
}
.navbar .navbar-logo-div{
    width: 25%;
}
.navbar .navbar-contact-div{
    width: 25%;
    justify-content: end;
    display: flex;
    align-items: center;
}
.navbar .nav-back{
    height: 100%;
    width: 100%;
    background-color: white;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    transform: translateY(-102%);
}
.navbar .navbar-links-div .link::after{
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    height: 3px;
    border-radius: 15px;
    width:100%;
    background-color: #1d70b8;
    opacity: 0;
    transition-duration: 0.5s;
    

}
.navbar .contact-us-nav{
    color: white;
    text-decoration: none;
}
.navbar .navbar-links-div .active.link::after{
    opacity: 1;
}
.navbar .navbar-links-div .link:hover::after{
    opacity: 1;
}
.navbar .link{
    color: black;
    text-decoration: none;
}
.navbar .mobile-navbar-div{
    display: none;
}
.navbar .btn{
    display: flex;
    height: 35px;
    width: 125px;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 !important;
}
.navbar .btn.btn-ru{
    width: 170px;
}
.navbar .btn p{
    width: 60%;
}
.navbar .active-lang-div{
    display: flex;
    align-items: center;
}
.navbar .active-lang-div img{
    height: 28px;
}
.navbar .lang-options-div .lang-option{
    display: flex;
    align-items: center;
}
.navbar .lang-options-div .lang-option img{
    height: 28px;
    margin-right: 5px;
}
.navbar .select-lang-div{
    width: auto !important;
}
@media screen and (max-width: 1920px) {
    .navbar .btn.btn-ru{
        width: 190px;
    }
    .navbar .btn{
        width: 150px;
    }
    

}
@media screen and (max-width: 1500px) {
    .navbar .navbar-links-div .link{
        padding: 5px;
        transition-duration: 1s;
        position: relative;
        font-size: 19px;
    }
    

}

@media screen and (max-width: 640px) {
    .navbar .navbar-logo{
        height: 28px;
    }
    .navbar .navbar-links-div{
        display: none;
    }
    .navbar .navbar-contact-div .nav-contact-btn{
        display: none;
    }
    .navbar .ham-menu{
        z-index: 1;
    }
    .navbar .ham-menu span{
        height: 5px;
        width: 30px;
        background-color: black;
        display: block;
        border-radius: 15px;
        margin-top: 2px;
        transition-duration: 0.5s;
    }
    .navbar .ham-menu span.rotate-45{
        transform: translateY(100%);
        opacity: 0;
    }
    .navbar .ham-menu span.minus-rotate-45{
        transform: translateY(-100%);
        opacity: 0;
        

    }
    .navbar .select-lang-div{
        width: auto !important;
        margin-right: 10%;
    }
    .navbar .mobile-navbar-div{
        background-color: white;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-101%);
    transition-duration: 1s;
    }
    .navbar .mobile-navbar-div.ham-open{
    transform: translateY(0);

    }
    .navbar .mobile-navbar-div  .mobile-link{
        font-size: 30px;
        font-family: MonumentExtended, kalameh;
        text-decoration: none;
        color: black;
        margin-bottom: 5%;
        /* font-weight: bold; */
        text-transform: uppercase;
    }
    .navbar .active-lang ,.navbar .lang-options-div .lang-option{
        font-size: 16px;
    }
}